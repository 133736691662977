import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore, alunoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import PedagogicoAlunoComponent from '@/components/PedagogicoAlunoComponent.vue';
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import FranchisePreInvoiceVisualizaComponent from '@/components/FranchisePreInvoiceVisualizaComponent.vue';
import LivroSolicitadoDiretoFranchiseButton from '@/components/LivroSolicitadoDiretoFranchiseButton.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
let Reposicao = class Reposicao extends Vue {
    constructor() {
        super(...arguments);
        // @ts-ignore
        this.loading = true;
        this.propertyToShowDetails = [
            'Alunos para rematrícula',
            'Alunos para troca de livro',
        ];
        this.headers = [
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Status do aluno na turma', value: 'status_do_aluno_na_turma_id' },
            { text: 'Turma', value: 'turma' },
            { text: 'Turma -  Livro', value: 'livro_nome' },
            { text: 'Turma - Modalidade', value: 'turma_modalidade_id' },
            { text: 'Turma - Horários', value: 'cronograma_horario' },
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Estágio atual - Financeiro do material didático', value: '104' },
            { text: 'Estágio atual - Financeiro do curso', value: '105' },
            {
                text: 'Próximo estágio - Primeira parc. do material didático',
                value: 'proximo_104',
            },
            { text: 'Estágio do contrato', value: 'estagio_id' },
            { text: 'Previsão Termino', value: 'previsao_termino' },
            { text: 'Contatos', value: 'ultimo_contato' },
            { text: 'Ações', value: 'actions' },
        ];
        this.search = '';
        this.tabelaRef = null;
        this.selectedContrato = null;
        this.aluno = null;
        this.retencaoAdministrativaQtd = 0;
        this.retencaoComercialQtd = 0;
        this.estagioFinalQtd = 0;
        this.estagioIntermediarioQtd = 0;
        this.retencaoPedagogicaQtd = 0;
        this.panelOpen = false;
        this.dialogFranchisePreInvoiceVisualiza = false;
        this.preInvoiceId = null;
    }
    getEstagioContrato(status) {
        switch (status) {
            case 1:
                return 'Estágio intermediário';
            case 2:
                return 'Rematrícula';
        }
    }
    get rematriculaItemsFiltered() {
        const estagioFinal = this.propertyToShowDetails.includes('Alunos para rematrícula');
        const estagioIntermediario = this.propertyToShowDetails.includes('Alunos para troca de livro');
        this.estagioFinalQtd = 0;
        this.estagioIntermediarioQtd = 0;
        const arr = [];
        _.forEach(dashboardStore.dashboardRematricula, (item) => {
            // @ts-ignore
            if (item.estagio_id === 1) {
                this.estagioIntermediarioQtd++;
                // @ts-ignore
            }
            else if (item.estagio_id === 2) {
                this.estagioFinalQtd++;
            }
            if (estagioFinal) {
                // @ts-ignore
                if (item.estagio_id == 2) {
                    arr.push(item);
                }
            }
            if (estagioIntermediario) {
                // @ts-ignore
                if (item.estagio_id == 1) {
                    arr.push(item);
                }
            }
        });
        return arr;
    }
    async showDetails(item) {
        const pedagogico = await alunoStore.getPedagogico(parseInt(item.aluno_id, 0));
    }
    async visualizarPreInvoice(preInvoiceId) {
        this.preInvoiceId = preInvoiceId;
        this.dialogFranchisePreInvoiceVisualiza = true;
    }
    async showAluno(item) {
        this.selectedContrato = null;
        this.selectedContrato = item;
        const form = this.$refs.showAluno;
        this.aluno = await alunoStore.getAluno(parseInt(this.selectedContrato.aluno_id, 0));
        form.show();
    }
    async onCancelFranchisePreInvoiceVisualiza() {
        this.preInvoiceId = null;
        this.dialogFranchisePreInvoiceVisualiza = false;
    }
    async carregaDados() {
        if (!this.panelOpen) {
            this.loading = true;
            await dashboardStore.getDashboardRematricula();
            this.loading = false;
            this.panelOpen = true;
        }
        else {
            this.panelOpen = false;
        }
        setTimeout(() => {
            this.tabelaRef = this.$refs.tabela;
        }, 1000);
    }
    async mounted() {
        this.carregaDados();
    }
};
Reposicao = __decorate([
    Component({
        components: {
            ClickableCard,
            EntidadeLinkComponent,
            FinanceiroAlunoComponent,
            FranchisePreInvoiceVisualizaComponent,
            LivroSolicitadoDiretoFranchiseButton,
            OcorrenciaComponent,
            OpenNewTabComponent,
            PedagogicoAlunoComponent,
            ShowPopup,
            TextExpandableComponent,
            ImprimirTabelaComponent,
        },
    })
], Reposicao);
export default Reposicao;
